<template>
  <div class="pd-mod">
    <div class="pd-mod__header">
      <span>Форма голосования для участника обсуждения</span>
      <button type="button" @click="$emit('close')" class="pd-mod__close" v-if="!loading">
        <CloseIcon />
      </button>
    </div>
    <div class="pd-mod__body">
      <form @submit.prevent="submitForm" class="pd-mod__form" autocomplete="off">
        <div class="pd-mod__description">
          Обращаем Ваше внимание, что принять участие в общественных обсуждениях могут только жители
          зарегистрированные, владеющие собственностью или работающие по месту проведения общественных
          обсуждений, <a class="link__blue" href="#">подробнее...</a>
        </div>
        <div class="pd-mod__form-item">
          <label class="pd-mod__form-label">Мой голос:</label>
          <div v-if="options.allTypes && options.allTypes.length" class="pd-mod__form-radio">
            <label v-for="(item, index) in options.allTypes" :key="index">
              <input v-model="form.type_id.value" type="radio" name="vote" :value="item.id" />
              <span v-if="item.name">{{ item.name }}</span>
            </label>
          </div>
          <span class="pd-mod__error" v-if="form.type_id.messages.length">
            {{ form.type_id.messages[0] || null }}
          </span>
        </div>
        <div class="pd-mod__form-item">
          <label for="remark_text" class="pd-mod__form-label">Замечания и предложения:</label>
          <textarea
            v-model="form.comment.value"
            id="remark_text"
            rows="10"
            placeholder="Оставьте свой комментарий, предложение или замечание"
          ></textarea>
          <span class="pd-mod__error" v-if="form.comment.messages.length">
            {{ form.comment.messages[0] || null }}
          </span>
        </div>
        <div class="pd-mod__form-item">
          <label class="pd-mod__form-label"
            >Документы, подтверждающие возможность участия в обсуждениях:</label
          >
          <div class="pd-mod__dropdown">
            <div>
              <a @click.prevent="dropdownActive = !dropdownActive" class="pd-mod__dropdown-title" href="#">
                Какие документы необходимы?
              </a>
            </div>
            <div :class="{ active: dropdownActive }" class="pd-mod__dropdown-description">
              Документы, подтверждающих сведения для физических лиц: фамилию, имя, отчество (при наличии),
              дату рождения, адрес места жительства (регистрации); для юридических лиц: наименование, основной
              государственный регистрационный номер, место нахождения и адрес. Документы из Единого
              государственного реестра недвижимости и иные документы, устанавливающие или удостоверяющие права
              на соответствующие земельные участков и (или) расположенных на них объектов капитального
              строительства и (или) помещений, являющихся частью указанных объектов капитального
              строительства, также представляют сведения соответственно о таких земельных участках, объектах
              капитального строительства, помещениях, являющихся частью указанных объектов капитального
              строительства.
            </div>
          </div>
          <LoadFilesComponent @load="loadFiles" />
          <span class="pd-mod__error" v-if="form.documents.messages.length">
            {{ form.documents.messages[0] || null }}
          </span>
        </div>

        <div class="pd-mod__form-item pd-mod__form-checkbox">
          <label>
            <input type="checkbox" v-model="form.agree.value" />
            <span
              >Я ознакомлен(а) с <a class="link__blue" href="#">правилами участия</a> в общественных
              обсуждениях</span
            >
          </label>
          <span class="pd-mod__error" v-if="form.agree.messages.length">
            {{ form.agree.messages[0] || null }}
          </span>
        </div>

        <button class="btn-blue btn--small" type="submit" :disabled="loading">
          <LoadingIndicator v-if="loading" />
          <template v-else>Отправить</template>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import CloseIcon from "components/svg/CloseIcon.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import LoadFilesComponent from "@/components/LoadFilesComponent.vue";
import UPLOAD_FILE from "@/graphql/UploadFile.graphql";
import VOTE_DISCUSSION from "@/graphql/pd/VoteDiscussion.graphql";

export default {
  name: "VoteModalComponent",
  props: {
    options: Object,
  },
  data() {
    return {
      showPassword: false,
      loading: false,
      error: false,
      dropdownActive: false,
      form: {
        type_id: {
          value: null,
          messages: [],
          required: true,
          type: "number",
        },
        comment: {
          value: null,
          messages: [],
          required: false,
          type: "string",
        },
        documents: {
          value: null,
          messages: [],
          required: true,
          type: "array",
        },
        agree: {
          value: null,
          messages: [],
          required: true,
          type: "bool",
        },
      },
      uploadFiles: [],
    };
  },
  methods: {
    loadFiles(files) {
      this.form.documents.value = files;
    },
    checkForm() {
      // Очистка
      Object.keys(this.form).forEach((key) => {
        this.form[key].messages = [];
      });
      // Проверка
      Object.keys(this.form).forEach((key) => {
        if (!["file", "bool", "number", "array"].includes(this.form[key].type)) {
          if (this.form[key].required && (this.form[key].value === null || this.form[key].value === "")) {
            this.form[key].messages.push("Поле обязательно для заполнения");
          }
        }
        if (this.form[key].type === "bool") {
          if (this.form[key].required && !this.form[key].value) {
            this.form[key].messages.push("Вы должны нажать на галочку");
          }
        }
        if (this.form[key].type === "number") {
          if (this.form[key].required && !this.form[key].value) {
            this.form[key].messages.push("Выберите один из вариантов");
          }
        }
        if (this.form[key].type === "array") {
          if (this.form[key].required && !this.form[key].value) {
            this.form[key].messages.push("Добавьте хотя бы один документ");
          }
        }
      });
      // Подсчет количества ошибок
      let errorsLength = 0;
      Object.keys(this.form).forEach((key) => {
        errorsLength += this.form[key].messages.length;
      });
      return errorsLength === 0;
    },
    uploadFile() {
      return new Promise((resolve) => {
        this.$apollo.provider.clients.pd
          .mutate({
            mutation: UPLOAD_FILE,
            variables: {
              files: this.form.documents.value,
              folder: "pd",
            },
            context: {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("apollo-token"),
              },
            },
          })
          .then(({ data }) => {
            if (data.UploadFile && data.UploadFile.length) {
              this.uploadFiles = data.UploadFile;
              this.uploadFiles.forEach((item, index) => {
                delete this.uploadFiles[index].__typename;
              });
            }
            resolve();
          });
      });
    },
    submitForm() {
      if (!this.loading) {
        if (this.checkForm()) {
          this.loading = true;
          this.uploadFile().then(() => {
            this.$apollo.provider.clients.pd
              .mutate({
                mutation: VOTE_DISCUSSION,
                variables: {
                  type_id: this.form.type_id.value,
                  discussion_id: this.options.id ? this.options.id : null,
                  comment: this.form.comment.value,
                  documents: this.uploadFiles,
                },
                context: {
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("apollo-token"),
                  },
                },
              })
              .then(() => {
                this.loading = false;
                this.close();
                location.reload();
              })
              .catch(({ graphQLErrors }) => {
                this.loading = false;
                graphQLErrors.forEach((err) => {
                  if (err.extensions.category === "validation") {
                    Object.keys(err.extensions.validation).forEach((key) => {
                      err.extensions.validation[key].forEach((message) => {
                        if (this.form[key]) {
                          this.form[key].messages.push(message);
                        }
                      });
                    });
                  }
                });
              });
          });
        }
      }
    },
    close() {
      this.$emit("close");
    },
  },
  components: {
    LoadingIndicator,
    CloseIcon,
    LoadFilesComponent,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/pd.styl"
</style>
