<template>
  <div class="breadcrumb" v-if="isBreadcrumbsVisible">
    <ul class="breadcrumb__list">
      <li v-for="(parent, index) in links" :key="index">
        <router-link
          :to="{ name: parent.name, params: parent.params, hash: parent.hash }"
          class="breadcrumb__link"
        >
          {{ parent.title }}
        </router-link>
        <BreadcrumbArrowIcon />
      </li>
      <li v-if="title">
        <span class="breadcrumb__title">
          {{ title }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import BreadcrumbArrowIcon from "components/svg/BreadcrumbArrowIcon.vue";

export default {
  name: "BreadcrumbsComponent",
  components: { BreadcrumbArrowIcon },
  props: {
    title: {
      type: String,
      required: true,
    },
    parentPages: {
      type: Array,
      required: false,
    },
  },
  computed: {
    links() {
      return this.parentPages && this.parentPages.length ? this.parentPages : this.$route.meta.parent;
    },
    isBreadcrumbsVisible() {
      return (
        (this.$route.meta && this.$route.meta.parent && this.$route.meta.parent.length) ||
        (this.parentPages && this.parentPages.length)
      );
    },
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/svg.styl"
@import "~@/styles/mixins/textcut.styl"
.breadcrumb {
  display flex
  flex-direction column
  align-items flex-start
  justify-content flex-start
  width 100%
  padding 0 32px
  color: var(--color_dark_o5);
  +below(1200px) {
    padding 0 24px
  }
  +below(380px) {
    padding 0 16px
  }

  &__list {
    display flex
    align-items center
    justify-content flex-start
    font-weight: normal;
    font-size: 0.875em
    line-height: 20px
    list-style none
    padding 20px 0 0
    max-width 100%
    overflow-x auto

    &::-webkit-scrollbar {
      display none
    }

    li {
      display flex

      &:not(:last-child) {
        flex-shrink 0
      }

      i {
        margin 0 8px
      }
    }

    a {
      color: var(--color_dark_o5);
      text-decoration-line: underline;
      position relative
    }
  }

  .icon {
    width 12px
    height 12px
    display inline-flex
    align-items center
    justify-content center
    margin auto 8px

    svg {
      width 100%
      height 100%
      svg(var(--color_gray_dark))
    }
  }

  &__link {
    text-decoration none !important
    transition 0.2s

    &:hover {
      text-decoration underline !important
    }
  }

  &__title {
    textcut(1)
    word-break: break-word;
  }
}
</style>
