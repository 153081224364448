<template>
  <figure class="icon">
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.41485 5.645L5.29485 3.525C5.24837 3.47814 5.19307 3.44094 5.13214 3.41556C5.07121 3.39017 5.00586 3.37711 4.93985 3.37711C4.87384 3.37711 4.80849 3.39017 4.74756 3.41556C4.68663 3.44094 4.63133 3.47814 4.58485 3.525C4.49172 3.61869 4.43945 3.74541 4.43945 3.8775C4.43945 4.0096 4.49172 4.13632 4.58485 4.23L6.35485 6L4.58485 7.77C4.49172 7.86369 4.43945 7.99041 4.43945 8.12251C4.43945 8.2546 4.49172 8.38132 4.58485 8.47501C4.63157 8.52135 4.68698 8.55801 4.7479 8.58289C4.80881 8.60777 4.87405 8.62039 4.93985 8.62C5.00565 8.62039 5.07088 8.60777 5.1318 8.58289C5.19272 8.55801 5.24813 8.52135 5.29485 8.47501L7.41485 6.355C7.46171 6.30852 7.49891 6.25322 7.5243 6.19229C7.54968 6.13136 7.56275 6.06601 7.56275 6C7.56275 5.934 7.54968 5.86865 7.5243 5.80772C7.49891 5.74679 7.46171 5.69149 7.41485 5.645Z"
        fill="#76767A"
      />
    </svg>
  </figure>
</template>

<script>
export default {
  name: "BreadcrumbArrowIcon",
};
</script>
