<template>
  <div class="pd-mod">
    <div class="pd-mod__header">
      <span>Консультация по материалам проекта</span>
      <button type="button" @click="$emit('close')" class="pd-mod__close" v-if="!loading">
        <CloseIcon />
      </button>
    </div>
    <div class="pd-mod__body">
      <form v-if="!isFormSent" @submit.prevent="submitForm" class="pd-mod__form" autocomplete="off">
        <div class="pd-mod__description">
          Задайте вопрос по содержанию и материалам проекта, вынесенное на общественное обсуждение. Ответ
          придёт на вашу электронную почту.
        </div>
        <div class="pd-mod__form-inputs">
          <div class="pd-mod__form-item" :class="{ error: form.email.messages.length }">
            <label for="pd_email" class="pd-mod__form-label">Ваша электронная почта</label>
            <input v-model="form.email.value" id="pd_email" placeholder="email@mail.ru" />
            <span class="pd-mod__error" v-if="form.email.messages.length">
              {{ form.email.messages[0] || null }}
            </span>
          </div>
          <div class="pd-mod__form-item" :class="{ error: form.question.messages.length }">
            <label for="pd_question" class="pd-mod__form-label">Ваш вопрос</label>
            <textarea
              v-model="form.question.value"
              id="pd_question"
              rows="10"
              placeholder="Сообщение"
            ></textarea>
            <span class="pd-mod__error" v-if="form.question.messages.length">
              {{ form.question.messages[0] || null }}
            </span>
          </div>
        </div>
        <button class="btn-blue btn--small" type="submit" :disabled="loading">
          <LoadingIndicator v-if="loading" />
          <template v-else>Отправить</template>
        </button>
      </form>

      <template v-else>
        <p class="pd-mod__answer-text">
          Спасибо за Ваш вопрос. <br />
          Ответ придёт на вашу электронную почту.
        </p>
        <button @click="$emit('close')" class="btn-blue btn--small">Закрыть</button>
      </template>
    </div>
  </div>
</template>

<script>
import CloseIcon from "components/svg/CloseIcon.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import ASK_CONSULTATION from "@/graphql/pd/AskConsultation.graphql";

export default {
  name: "ConsultationModalComponent",
  props: {
    options: Object,
  },
  data() {
    return {
      showPassword: false,
      loading: false,
      error: false,
      dropdownActive: false,
      isFormSent: false,
      form: {
        email: {
          value: null,
          messages: [],
          required: true,
          type: "string",
        },
        question: {
          value: null,
          messages: [],
          required: true,
          type: "string",
        },
      },
    };
  },
  beforeMount() {
    this.form.email.value = this.options && this.options.email ? this.options.email : null;
  },
  methods: {
    checkForm() {
      // Очистка
      Object.keys(this.form).forEach((key) => {
        this.form[key].messages = [];
      });
      // Проверка
      Object.keys(this.form).forEach((key) => {
        if (!["file", "bool", "number", "array"].includes(this.form[key].type)) {
          if (this.form[key].required && (this.form[key].value === null || this.form[key].value === "")) {
            this.form[key].messages.push("Поле обязательно для заполнения");
          }
        }
        if (this.form[key].type === "bool") {
          if (this.form[key].required && !this.form[key].value) {
            this.form[key].messages.push("Вы должны нажать на галочку");
          }
        }
        if (this.form[key].type === "number") {
          if (this.form[key].required && !this.form[key].value) {
            this.form[key].messages.push("Выберите один из вариантов");
          }
        }
        if (this.form[key].type === "array") {
          if (this.form[key].required && !this.form[key].value) {
            this.form[key].messages.push("Добавьте хотя бы один документ");
          }
        }
      });
      // Подсчет количества ошибок
      let errorsLength = 0;
      Object.keys(this.form).forEach((key) => {
        errorsLength += this.form[key].messages.length;
      });
      return errorsLength === 0;
    },
    submitForm() {
      if (!this.loading) {
        if (this.checkForm()) {
          this.loading = true;
          this.$apollo.provider.clients.pd
            .mutate({
              mutation: ASK_CONSULTATION,
              variables: {
                discussion_id: this.options.id ? this.options.id : null,
                email: this.form.email.value,
                question: this.form.question.value,
              },
              context: {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("apollo-token"),
                },
              },
            })
            .then(({ data }) => {
              this.loading = false;
              this.isFormSent = true;
              setTimeout(() => {
                this.close();
              }, 2500);
            })
            .catch(({ graphQLErrors }) => {
              this.loading = false;
              graphQLErrors.forEach((err) => {
                if (err.extensions.category === "validation") {
                  Object.keys(err.extensions.validation).forEach((key) => {
                    err.extensions.validation[key].forEach((message) => {
                      if (this.form[key]) {
                        this.form[key].messages.push(message);
                      }
                    });
                  });
                }
              });
            });
        }
      }
    },
    close() {
      this.$emit("close");
    },
  },
  components: {
    LoadingIndicator,
    CloseIcon,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/pd.styl"
</style>
